// methods
import {
  getLessonList,
  addSubject,
  editSubject,
  setSubjectStatus,
  getIsRelateTeaching,
  deleteSubject,
} from '@/api/basic';

// components
import { Button, Table, TableColumn, Form, FormItem, Input } from 'element-ui';
import basicHeader from '@/components/backStageHeader';
import qlPagination from '@/components/qlPagination';
import ETable from '@components/table/index.vue';
import OperationBtns from '@components/table/components/operationBtns.vue';
import EButton from '@components/button/index.vue';
import Dialog from '@components/dialog/dialog.vue';
import NoData from '@components/noData/index.vue';

// utils
import cloneDeep from 'lodash.clonedeep';
import Vue from 'vue';

export default {
  name: 'subject',

  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    basicHeader,
    qlPagination,
    ETable,
    EButton,
    Dialog,
    NoData,
  },

  data() {
    return {
      loading: false,
      listData: {
        list: [],
        pageSize: 10,
        pageIndex: 1,
        total: 0,
      },
      isEdit: false,
      editIndex: -1,
      visible: false,

      sectionList: [],

      dialogData: {
        lessonId: null,
        lessonName: '',
      },

      subjectModal: {
        visible: false,
        title: '新增自定义学科',
        rules: {
          periodName: [{ required: true }],
          lessonDefName: [{ required: true }],
          lessonName: [
            { required: true, message: '请输入学科别名', trigger: 'blur' },
            { max: 20, message: '20字符，支持汉字/字母/数字/特殊字符', trigger: 'blur' },
          ],
        },
        form: {},
      },
    };
  },

  methods: {
    showAddSubject(row) {
      let title;
      if (!row.lessonId) title = '新增自定义学科';
      else if (row.lessonDefName) title = '编辑系统学科';
      else title = '编辑自定义学科';

      this.subjectModal = {
        ...this.subjectModal,

        visible: true,
        title,
        form: cloneDeep(row),
      };
    },

    async getTableData() {
      const res = await getLessonList();

      if (res.status === 0) {
        this.sectionList = res.result;
      }
    },

    async handleSave() {
      this.loading = true;

      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const { periodId, lessonName, lessonId } = this.subjectModal.form;

          let res;
          if (lessonId) {
            res = await editSubject({
              lessonId,
              lessonName,
            }).catch(() => (this.loading = false));
          } else {
            res = await addSubject({
              periodId,
              lessonName,
            }).catch(() => (this.loading = false));
          }

          if (res.status === 0) {
            this.$message.success(lessonId ? '编辑成功' : '新增成功');
            this.subjectModal.visible = false;
            this.getTableData();
          }
        } else {
          this.loading = false;
        }
      });
    },

    cancelAddSubject() {
      this.$refs.form.clearValidate();
      this.subjectModal.visible = false;
    },

    // 禁用
    async handleBan(row) {
      // 直接开启
      if (row.status === 1) {
        setSubjectStatus({ lessonId: row.lessonId, status: row.status === 1 ? 0 : 1 }).then((res) => {
          if (res.status === 0) {
            this.$message.success('操作成功');
            this.getTableData();
          }
        });

        return;
      }

      const h = this.$createElement;
      // 判断是否关联任教
      const res = await getIsRelateTeaching({
        lessonId: row.lessonId,
      });

      if (res.status === 0) {
        const { result } = res;

        if (result) {
          this.$msgbox({
            message: h('div', null, [
              h('h3', { class: 'T3B mg-b-13' }, `【${row.lessonName}】已被关联任教，无法禁用`),
              h('p', { class: 'T5-2' }, `请删除与该学科相关的任教，再禁用学科`),
            ]),
            type: 'warning',
            customClass: 'ql-message-box_notitle',
            showClose: false,
            showCancelButton: false,
          });

          return;
        }
      }

      this.$msgbox({
        message: h('div', null, [
          h('h3', { class: 'T3B mg-b-13' }, `确认要${row.status === 0 ? '禁用' : '启用'}【${row.lessonName}】吗？`),
          h('p', { class: 'T5-2' }, '禁用后各业务模块的学科筛选项中不可见该学科，该学科不纳入任何统计'),
        ]),
        type: 'warning',
        customClass: 'ql-message-box_notitle',
        showClose: false,
        showCancelButton: true,
        confirmButtonText: row.status === 0 ? '禁用' : '确定',
        confirmButtonClass: row.status === 0 ? 'btn-ghost-danger' : '',
      })
        .then(() => {
          setSubjectStatus({ lessonId: row.lessonId, status: row.status === 1 ? 0 : 1 }).then((res) => {
            if (res.status === 0) {
              this.$message.success('操作成功');
              this.getTableData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },

    // 删除
    async handleDel(row) {
      const h = this.$createElement;
      // 判断是否关联任教
      const res = await getIsRelateTeaching({
        lessonId: row.lessonId,
      });

      if (res.status === 0) {
        const { result } = res;

        if (result) {
          this.$msgbox({
            message: h('div', null, [
              h('h3', { class: 'T3B mg-b-13' }, `【${row.lessonName}】已被关联任教，无法删除`),
              h('p', { class: 'T5-2' }, `请删除与该学科相关的任教，再删除学科`),
            ]),
            type: 'warning',
            customClass: 'ql-message-box_notitle',
            showClose: false,
            showCancelButton: false,
          });

          return;
        }
      }

      this.$msgbox({
        message: h('div', null, [
          h('h3', { class: 'T3B mg-b-13' }, `确定要删除【${row.lessonName}】吗？`),
          h('p', { class: 'T5-2' }, '删除后各业务模块的学科筛选项中不可见该学科，该学科不纳入任何统计'),
        ]),
        type: 'warning',
        customClass: 'ql-message-box_notitle',
        showClose: false,
        showCancelButton: true,
        confirmButtonText: '删除',
        confirmButtonClass: 'btn-ghost-danger',
      })
        .then(() => {
          deleteSubject({ lessonId: row.lessonId }).then((res) => {
            if (res.status === 0) {
              this.$message.success('删除成功');
              this.getTableData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
  },

  mounted() {
    this.getTableData();
  },

  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },

    notAllowOperate() {
      return false;
    },

    columns() {
      return [
        {
          label: '学科别名',
          prop: 'lessonName',
          component: Vue.extend({
            props: ['row', 'col'],
            render() {
              return <span class={this.row.status === 1 ? 'text-F3' : ''}>{this.row.lessonName || '-'}</span>;
            },
          }),
        },
        {
          label: '关联学科',
          prop: 'lessonDefName',
          formatter: (row) => row.lessonDefName || '无',
          component: Vue.extend({
            props: ['row', 'col'],
            render() {
              return <span class={this.row.status === 1 ? 'text-F3' : ''}>{this.row.lessonDefName || '无'}</span>;
            },
          }),
        },
        {
          label: '操作',
          props: () => ({
            btnList: [
              {
                text: '编辑',
                disabled: (row) => row.status === 1,
                handle: this.showAddSubject,
              },
              {
                // 系统学科可以禁用/启用
                textHandle: (row) => ({ 0: '禁用', 1: '启用' }[row.status]),
                hidden: (row) => !row.lessonDefId,
                disabled: () => this.notAllowOperate,
                handle: this.handleBan,
              },
              {
                // 自定义学科可以删除
                text: '删除',
                hidden: (row) => row.lessonDefId,
                disabled: () => this.notAllowOperate,
                handle: this.handleDel,
              },
            ],
          }),
          component: OperationBtns,
        },
      ];
    },
  },

  watch: {
    'subjectModal.visible'(visible) {
      if (visible) {
        this.loading = false;
      }
    },
  },
};
